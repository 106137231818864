@import "https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,300,600,700,800";
@font-face {
  font-family: Zephyrus;
  src: url("zephyrus.89f3e3f5.eot");
  src: url("zephyrus.89f3e3f5.eot#iefix") format("embedded-opentype"), url("zephyrus.e02d71cd.woff2") format("woff2"), url("zephyrus.dde7aadd.woff") format("woff"), url("zephyrus.213e1d4c.ttf") format("truetype"), url("zephyrus.fed93d9f.svg#Zephyrus") format("svg");
  font-weight: normal;
  font-style: normal;
}

p {
  color: #1c365399;
  padding-bottom: 20px;
  font-size: 15px;
  line-height: 29px;
}

h1 {
  font-size: 60px;
}

h2 {
  color: #3d4351;
  font-size: 40px;
}

h3 {
  color: #3d4351;
  letter-spacing: 3px;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}

h4 {
  color: #3d4351;
  font-size: 18px;
}

h5 {
  color: #3d4351;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
}

.calligraph {
  font-family: Zephyrus, serif;
}

.bold {
  font-weight: 700;
}

.light {
  font-weight: 300;
}

.btn {
  text-transform: uppercase;
  border: 2px solid;
  border-radius: 40px;
  font-size: 13px;
  display: inline-block;
}

.btn:hover, .btn:focus {
  color: #fff;
  background-color: #e8ca6f;
  border-color: #e8ca6f;
}

.btn-white {
  border: 2px solid #fff;
  border-radius: 40px;
  font-size: 13px;
  display: inline-block;
}

.btn-white:hover, .btn-white:focus {
  color: #e8ca6f;
  border-color: #e8ca6f;
}

.btn-fill {
  color: #fff;
  text-transform: uppercase;
  background-color: #e8ca6f;
  border: 2px solid #e8ca6f;
  border-radius: 40px;
  display: inline-block;
}

.btn-fill:hover, .btn-fill:focus {
  color: #fff;
  background-color: #d3b56a;
  border-color: #d3b56a;
}

.btn-small {
  padding: 8px 30px;
}

.btn-large {
  padding: 15px 40px;
}

.btn-margin-right {
  margin-right: 20px;
}

section.intro, section.rsvp {
  background: #fff;
}

section.events, section.map, #photo-carousel, #rsvp-response {
  background: #f8f9fd;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

a {
  color: #e8ca6f;
  transition-property: color, border-color, background-color;
  transition-duration: .2s;
  transition-timing-function: ease;
}

a:hover, a:focus {
  color: #d3b56a;
  text-decoration: none;
}

body {
  color: #1c365399;
  -webkit-text-size-adjust: 100%;
  font-family: Open Sans, sans-serif;
  font-weight: 300;
}

section {
  overflow-x: hidden;
}

:focus {
  outline: none !important;
}

ul, ol {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

section .header {
  padding-bottom: 30px;
}

.section-padding {
  padding: 40px 0;
}

.section-margin {
  margin: 40px 0;
}

.section-border {
  border-bottom: 1px solid #e6e9ea;
}

.tooltip {
  z-index: 1070;
  visibility: visible;
  filter: alpha(opacity= 0);
  opacity: 0;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.4;
  display: block;
  position: absolute;
}

.tooltip.in {
  filter: alpha(opacity= 90);
  opacity: .9;
}

.tooltip.top {
  margin-top: -3px;
  padding: 5px 0;
}

.tooltip.right {
  margin-left: 3px;
  padding: 0 5px;
}

.tooltip.bottom {
  margin-top: 3px;
  padding: 5px 0;
}

.tooltip.left {
  margin-left: -3px;
  padding: 0 5px;
}

.tooltip-inner {
  max-width: 200px;
  color: #fff;
  text-align: center;
  text-transform: capitalize;
  background-color: #000;
  border-radius: 4px;
  padding: 3px 8px;
  text-decoration: none;
}

.row-no-padding [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.pulse2 {
  animation-name: pulse2;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes pulse2 {
  0% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(.8);
  }

  100% {
    transform: scale(1);
  }
}

.floating-arrow {
  animation-name: floating-arrow;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes floating-arrow {
  from {
    transform: translate(-50%);
  }

  65% {
    transform: translate(-50%, 15px);
  }

  to {
    transform: translate(-50%);
  }
}

.floating-logo {
  animation-name: floating-logo;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes floating-logo {
  from {
    transform: translate(-50%);
  }

  50% {
    transform: translate(-50%, 10px);
  }

  to {
    transform: translate(-50%);
  }
}

.wp0, .wp1, .wp2, .wp3, .wp4, .wp5, .wp6, .wp7, .wp7-1, .wp8, .wp8-1, .wp9, .wp10 {
  visibility: hidden;
}

.wp4, .wp5, .wp6, .wp10 {
  animation-delay: .3s;
}

.bounceInLeft, .bounceInRight, .fadeInUp, .fadeInDown, .fadeInLeft, .fadeInRight, .bounceInDown, .zoomIn, .zoomOutLeft, .zoomOutRight, .slideInLeft, .wobble {
  visibility: visible;
}

.header-nav.open {
  visibility: visible;
  opacity: .9;
  transition: opacity .5s;
}

.nav-toggle {
  z-index: 999999;
  cursor: pointer;
  padding: 10px 35px 16px 0;
  position: absolute;
  top: 0;
  right: 15px;
}

.nav-toggle:focus {
  outline: none;
}

.nav-toggle span, .nav-toggle span:before, .nav-toggle span:after {
  content: "";
  width: 35px;
  height: 3px;
  cursor: pointer;
  background: #fff;
  border-radius: 1px;
  display: block;
  position: absolute;
}

.nav-toggle span:before {
  top: -10px;
}

.nav-toggle span:after {
  bottom: -10px;
}

.nav-toggle span, .nav-toggle span:before, .nav-toggle span:after {
  -o-transition: all 300ms ease-in-out;
  transition: all .3s ease-in-out;
}

.nav-toggle.active span {
  background-color: #0000;
}

.nav-toggle.active span:before, .nav-toggle.active span:after {
  top: 0;
}

.nav-toggle.active span:before {
  transform: rotate(45deg);
}

.nav-toggle.active span:after {
  top: 10px;
  transform: translateY(-10px)rotate(-45deg);
}

.navicon {
  height: 26px;
  visibility: hidden;
  transition: all .3s ease-in-out;
  position: absolute;
  top: 48px;
  right: 10px;
}

.hero {
  min-height: 100vh;
  background-blend-mode: darken;
  webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background: #37242866 url("hero-min.ace645b6.webp") center / cover;
  position: relative;
}

.hero h1 {
  color: #fff;
  margin-bottom: 40px;
}

.hero .hero-content {
  min-height: 85vh;
}

.hero .hero-content img {
  width: 56%;
  position: absolute;
  bottom: 2%;
  left: 22%;
}

.navigation {
  width: 100%;
  z-index: 999;
  transition: all .3s ease-in-out;
  position: fixed;
}

.navigation.dev:after {
  content: "DEVELOP";
  width: 100%;
  opacity: .7;
  color: #fff;
  text-align: center;
  background-color: red;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

.fixed {
  background-color: #372428e0;
}

header {
  width: 1170px;
  border-bottom: 1px solid #fff3;
  margin: 0 auto;
  padding: 50px 0 10px;
  transition: padding .3s ease-in-out;
  position: relative;
}

header a {
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
}

header a.login {
  margin-right: 20px;
}

header .logo {
  display: inline-block;
}

header .logo img {
  height: 50px;
}

header ul.primary-nav {
  margin: 0 0 0 75px;
  padding: 0;
}

header ul.primary-nav li {
  display: inline;
}

header ul.primary-nav li a {
  color: #fff;
  padding-right: 25px;
}

header ul.primary-nav li a:hover {
  color: #e8ca6f;
}

header ul.primary-nav li:last-child a {
  padding-right: 0;
}

header ul.member-actions li {
  display: inline;
}

header ul.member-actions li a {
  color: #fff;
}

header ul.member-actions li a:hover {
  color: #e8ca6f;
}

header .header-nav {
  display: inline-block;
}

header .member-actions {
  transition: all .3s ease-in-out;
  position: absolute;
  top: 55px;
  right: 0;
}

.down-arrow {
  color: #fff;
  font-size: 30px;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
}

.down-arrow a {
  color: #fff;
}

#countdown {
  overflow: visible;
}

#countdown .ribbon {
  width: 150px;
  height: 235px;
  color: #fff;
  background: #d59e79;
  font-weight: 500;
  display: inline-block;
}

#countdown .ribbon .ribbon-emojis {
  font-size: 3em;
}

#countdown .ribbon .ribbon-rem-days {
  font-size: 5em;
}

#countdown .ribbon .ribbon-first-line {
  font-size: 2em;
}

#countdown .ribbon .ribbon-second-line {
  font-size: 1em;
}

#countdown .ribbon:after {
  content: "";
  width: 0;
  height: 0;
  border: 75px solid #d59e79;
  border-top-width: 10px;
  border-bottom: 25px solid #0000;
  display: block;
  position: relative;
  top: 14px;
}

#intro {
  padding-top: 40px;
}

#intro .iandmlogo {
  width: 25%;
}

#intro img {
  width: 100%;
  height: auto;
}

#intro p {
  text-align: center;
}

#intro .poem {
  color: #3d4351;
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 24px;
}

#intro .poem-author {
  color: #3d4351;
  padding-left: 10em;
  font-size: small;
  font-weight: 700;
}

#photo-carousel {
  width: 100%;
  height: 80vh;
  position: relative;
  overflow: hidden;
}

#photo-deck, #swipe-helper-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#photo-deck > div {
  width: 100vw;
  height: 100vh;
  will-change: transform;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

#photo-deck > div > div {
  box-sizing: content-box;
  will-change: transform;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1em solid #fff;
  border-bottom-width: 4em;
  border-radius: 3px;
  box-shadow: 0 12.5px 100px -10px #32324966, 0 10px 10px -10px #3232494d;
}

#swipe-helper-overlay i {
  color: #fff;
  background-color: #d59e79d9;
  border-radius: 50%;
  padding: 16px 20px;
  font-size: 5em;
  position: absolute;
  top: 35vh;
}

.events .leftcol {
  border-right: 1px solid #e6e9ea;
  padding-right: 100px;
}

.events .rightcol {
  padding-left: 100px;
}

.events .families {
  border-top: 1px solid #e6e9ea;
  margin-top: 40px;
}

.events .families img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 2em;
  box-shadow: 0 5px 15px #00000040;
}

.events .families p {
  padding-bottom: 0;
}

.events .time {
  color: #1c365399;
}

#instagram {
  overflow: hidden;
}

#instagram img {
  width: 100%;
}

#instagram .instagram-gradient {
  background-image: linear-gradient(45deg, #fdf497 0%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#video-bg {
  height: 300px;
  overflow: hidden;
}

#video-content {
  width: 100%;
  height: 100%;
  text-align: center;
  background: #11111180;
  padding-top: 110px;
  position: relative;
}

#video-content h5 {
  color: #fff;
  letter-spacing: 10px;
  font-size: 30px;
  font-weight: 300;
}

#video-content p {
  color: #fff;
}

#map-canvas, #map-content-wrapper {
  height: 500px;
}

#map-canvas {
  width: 100%;
}

#map-content-wrapper {
  margin-top: -502px;
  position: relative;
}

#map-content {
  background: #fff;
  margin-top: 40px;
  padding: 50px 60px;
  transition: opacity .5s, visibility .6s, margin-top .5s;
}

#btn-show-content {
  cursor: pointer;
  background: #fff;
  padding: 20px;
  transition: opacity .5s, visibility .6s, margin-top .5s;
  display: inline-block;
}

.toggle-map-content {
  opacity: 0;
  visibility: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

#btn-show-accomodation {
  margin: 4em;
}

#stay22-widget {
  width: 100%;
  height: 80vh;
}

.rsvp {
  padding-bottom: 140px;
}

.rsvp .rsvp-form .rsvp-btn {
  width: 100%;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 15px 0;
  font-size: 13px;
}

.rsvp .rsvp-form .form-group {
  border: 1px solid #e6e9ea;
  border-radius: 5px;
  margin: 0 auto 10px;
  padding: 1em;
}

.rsvp .rsvp-form .form-group > * {
  margin: 1em 0;
}

.rsvp .rsvp-form .form-group label:nth-child(1) {
  margin: 0;
}

.rsvp .rsvp-form .form-group label:last-child {
  margin-left: 1em;
}

.rsvp .rsvp-form .form-group input[type="radio"], .rsvp .rsvp-form .form-group input[type="checkbox"] {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  position: relative;
}

.rsvp .rsvp-form .form-group .title {
  border-radius: .5em;
  padding: 5px 5px 5px 25px;
}

.rsvp .rsvp-form .form-group .checkmark {
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: .5em;
  position: absolute;
}

.rsvp .rsvp-form .form-group input:checked ~ .checkmark, .rsvp .rsvp-form .form-group input:checked ~ .title {
  color: #fff;
  background-color: #e8ca6f;
}

.rsvp .rsvp-form .form-group input:checked ~ .checkmark:after {
  display: block;
}

.rsvp .rsvp-form .form-group .checkmark:after {
  content: "";
  width: 7px;
  height: 14px;
  border: 3px solid #fff;
  border-width: 0 3px 3px 0;
  display: none;
  position: absolute;
  top: 2px;
  left: 7px;
  transform: rotate(45deg);
}

.rsvp .rsvp-form .form-input-group {
  width: 100%;
  height: 55px;
  text-align: left;
  border: 1px solid #e6e9ea;
  border-radius: 5px;
  margin: 0 auto 10px;
  position: relative;
}

.rsvp .rsvp-form .form-input-group i {
  color: #e8ca6f;
  font-size: 14px;
}

.rsvp .rsvp-form .form-input-group i:after {
  content: "";
  height: 30px;
  width: 1px;
  border-right: 1px solid #e6e9ea;
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
}

.rsvp .rsvp-form .form-input-group i.fa-user, .rsvp .rsvp-form .form-input-group i.fa-users, .rsvp .rsvp-form .form-input-group i.fa-phone, .rsvp .rsvp-form .form-input-group .fa-key {
  margin-left: 20px;
  font-size: 14px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.rsvp .rsvp-form .form-input-group input {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 6px;
  padding-left: 68px;
}

.rsvp .rsvp-form .form-input-group input::-webkit-input-placeholder {
  color: #bcc1c3;
}

.rsvp .rsvp-form .form-input-group input:-moz-placeholder {
  color: #bcc1c3;
}

.rsvp .rsvp-form .form-input-group input::-moz-placeholder {
  color: #bcc1c3;
}

.rsvp .rsvp-form .form-input-group input:-ms-input-placeholder {
  color: #bcc1c3;
}

.rsvp #rsvp-response {
  border: 1px solid #e6e9ea;
  border-radius: 1em;
}

.rsvp #add-to-cal a {
  background-position: 20%;
  background-repeat: no-repeat;
  background-size: 15px 100%;
  margin: 30px 3px;
}

.rsvp #add-to-cal a.icon-google {
  background-image: url("icon-google.f049229a.svg");
}

.rsvp #add-to-cal a.icon-ical {
  background-image: url("icon-apple.f3554bfe.svg");
}

.rsvp #add-to-cal a.icon-outlook {
  background-image: url("icon-outlook.72dd3d50.svg");
}

.rsvp #add-to-cal a.icon-yahoo {
  background-image: url("icon-yahoo.8b1cbd51.svg");
}

footer .content {
  border-top: 1px solid #e6e9ea;
  padding-bottom: 20px;
}

footer .to-top-wrapper {
  cursor: pointer;
  width: 50px;
  height: 50px;
  background: #fff;
  border: 1px solid #e6e9ea;
  border-radius: 50%;
  margin: -25px auto 50px;
  font-size: 30px;
  display: block;
}

footer .to-top-wrapper .to-top {
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  display: block;
}

footer .to-top-wrapper .to-top:hover {
  margin-top: -5px;
}

footer span.fa-heart {
  color: #ff415c;
  margin: 0 2px;
  font-size: 18px;
}

footer .credits a:after {
  content: "";
  width: 7em;
  height: 3em;
  filter: contrast(.1);
  -o-transition: all .4s;
  background: url("rect-transp-black.a8003431.svg") 100% / contain no-repeat;
  margin: auto;
  transition: all .4s;
  display: block;
}

footer .credits a:hover:after {
  filter: contrast();
}

body.modal-open {
  padding-right: 0 !important;
}

.modal-body {
  padding: 25px;
}

@media screen and (max-width: 1200px) {
  header {
    width: 90%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 991px) {
  header {
    width: 90%;
  }

  .header-nav {
    width: 100%;
    height: 100%;
    z-index: 99999;
    visibility: hidden;
    opacity: 0;
    background-color: #372428;
    transition: opacity .5s, visibility 0s .5s;
    position: fixed;
    top: 0;
    left: 0;
  }

  ul.member-actions {
    position: static;
  }

  .navicon {
    visibility: visible;
  }

  header .nav-wrapper {
    display: absolute;
  }

  header nav {
    position: relative;
    top: 45%;
    transform: translateY(-45%);
  }

  nav ul li a {
    color: #fff;
    text-transform: uppercase;
    -o-transition: all 300ms ease-in-out;
    font-size: 25px;
    font-weight: 600;
    transition: all .3s ease-in-out;
  }

  nav ul li {
    margin-bottom: 25px;
  }

  nav ul.primary-nav li, nav ul.member-actions li {
    display: block;
  }

  nav a.login {
    margin-right: 0;
  }

  nav ul.primary-nav {
    text-align: center;
    margin: 0;
    padding: 0;
  }

  nav ul.primary-nav li a {
    padding-right: 0;
  }

  nav .member-actions {
    text-align: center;
    padding: 0;
    position: static;
  }

  nav ul.primary-nav li, nav ul.member-actions li {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity= 0);
    opacity: 0;
    -o-transition: -o-transform .5s, opacity .5s;
    -moz-transition: -moz-transform .5s, opacity .5s;
    -webkit-transition: -webkit-transform .5s, opacity .5s;
    transition: transform .5s, opacity .5s;
    -o-transition: -o-transform .5s, opacity .5s;
    -moz-transition: -moz-transform .5s, opacity .5s;
    -webkit-transition: -webkit-transform .5s, opacity .5s;
    transition: transform .5s, opacity .5s;
    transform: translate3d(0, -80px, 0);
  }

  header .header-nav.open ul.primary-nav li, header .header-nav.open ul.member-actions li {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity= 100);
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  nav ul.primary-nav li:first-child {
    -o-transition-delay: .05s;
    transition-delay: 50ms;
  }

  nav ul.primary-nav li:nth-child(2) {
    -o-transition-delay: .1s;
    transition-delay: .1s;
  }

  nav ul.primary-nav li:nth-child(3) {
    -o-transition-delay: .15s;
    transition-delay: .15s;
  }

  nav ul.primary-nav li:nth-child(4) {
    -o-transition-delay: .2s;
    transition-delay: .2s;
  }

  nav ul.member-actions li:first-child {
    -o-transition-delay: .25s;
    transition-delay: .25s;
  }

  nav ul.member-actions li:nth-child(2) {
    -o-transition-delay: .3s;
    transition-delay: .3s;
  }

  .last {
    margin: 0;
  }

  #intro .poem {
    margin-top: 1em;
  }

  .events .leftcol {
    border-right: none;
    margin-bottom: 15px;
    padding: 0 15px;
  }

  .events .rightcol {
    margin-top: 30px;
    padding: 0 20px;
  }

  .events .families {
    border-top: none;
  }

  #map-content {
    margin-top: 0;
    padding: 35px;
  }

  #video-bg {
    height: 190px;
  }

  #video-content {
    padding-top: 55px;
  }

  .rsvp {
    padding-top: 40px;
  }
}

@media screen and (max-width: 768px) {
  .hero .hero-content img {
    width: 76%;
    bottom: 0;
    left: 12%;
  }
}

@media screen and (max-width: 640px) {
  .hero .hero-content img {
    width: 86%;
    left: 7%;
  }

  #eng-pics .img-wrap i {
    width: 25px;
    height: 25px;
    font-size: 25px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

@media screen and (max-width: 480px) {
  .hero .hero-content img {
    width: 100%;
    left: 0;
  }

  .hero .btn {
    width: 80%;
    margin: 0 auto;
    display: block;
  }

  .hero .btn:first-of-type {
    margin-bottom: 20px;
  }

  .hero h1 {
    font-size: 50px;
  }

  .btn-margin-right {
    margin-right: 0;
  }
}

@media screen and (max-width: 320px) {
  .rsvp {
    padding-top: 20px;
  }
}

/*# sourceMappingURL=index.1edb022e.css.map */
