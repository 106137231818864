// Colours partial

// Text colours
$txt-primary: rgba(28, 54, 83, 0.6);
// body font
$txt-secondary: #3D4351;
// heading font
$txt-light: #fff;
// header/hero
$txt-accent: #ACB1B4;
$form-placeholder: #BCC1C3;
// form placeholder text
// Global colours
$accent-color: #e8ca6f;
// global accent colour
$accent-color-hover: #d3b56a;
// global accent colour hover
$theme-light-color: #D59E79;
$theme-light-color-faded: rgba(213, 158, 121, 0.85);
$theme-dark-color: #372428;
$theme-dark-color-faded: rgba(55, 36, 40, .88);
$theme-dark-color-overlay: rgba(55, 36, 40, .4);
$white-color: #fff;
$black-color: #000;
// Typography colours
$h2-color: #3D4351;
$h3-color: #3D4351;
$h4-color: #3D4351;
$h5-color: #3D4351;
// Layout colours
$section-light: #fff;
$section-dark: #f8f9fd;
$to-top-bg: #495061;
$footer-primary: #3D4351;
$footer-secondary: #373D4A;
// Keylines
$keyline-color: #E6E9EA;
$footer-keyline-color: #4E566C;