// Imports
//@import "compass";
@import "partials/typography";
@import "partials/buttons";
@import "partials/colors";
@import "partials/layout";

/* ==========================================================================
Global Styles
========================================================================== */
.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

a {
  color: $accent-color;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-property: color, border-color, background-color;
  transition-property: color, border-color, background-color;
}

a:hover,
a:focus {
  color: $accent-color-hover;
  text-decoration: none;
}

body {
  font-family: $sans-serif;
  font-weight: $light;
  color: $txt-primary;
  -webkit-text-size-adjust: 100%;
  //fix for iOS
}

section {
  overflow-x: hidden;
}

*:focus {
  outline: none !important;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

section .header {
  padding-bottom: 30px;
}

.section-padding {
  padding: $section-padding;
}

.section-margin {
  margin: $section-margin;
}

.section-border {
  border-bottom: 1px solid $keyline-color;
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.4;
  visibility: visible;
  filter: alpha(opacity=0);
  opacity: 0;
}

.tooltip.in {
  filter: alpha(opacity=90);
  opacity: .9;
}

.tooltip.top {
  padding: 5px 0;
  margin-top: -3px;
}

.tooltip.right {
  padding: 0 5px;
  margin-left: 3px;
}

.tooltip.bottom {
  padding: 5px 0;
  margin-top: 3px;
}

.tooltip.left {
  padding: 0 5px;
  margin-left: -3px;
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: $white-color;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  background-color: $black-color;
  border-radius: 4px;
}

.row-no-padding {
  [class*="col-"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

/* ==========================================================================
Animations
========================================================================== */
.pulse2 {
  animation-name: pulse2;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes pulse2 {
  0% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}

.floating-arrow {
  animation-name: floating-arrow;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating-arrow {
  from {
    transform: translate(-50%, 0)
  }

  65% {
    transform: translate(-50%, 15px)
  }

  to {
    transform: translate(-50%, 0)
  }
}

.floating-logo {
  animation-name: floating-logo;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating-logo {
  from {
    transform: translate(-50%, 0)
  }

  50% {
    transform: translate(-50%, 10px)
  }

  to {
    transform: translate(-50%, 0)
  }
}

/* ==========================================================================
Waypoinsts
========================================================================== */
.wp0,
.wp1,
.wp2,
.wp3,
.wp4,
.wp5,
.wp6,
.wp7,
.wp7-1,
.wp8,
.wp8-1,
.wp9,
.wp10 {
  visibility: hidden;
}

.wp4,
.wp5,
.wp6,
.wp10 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.bounceInLeft,
.bounceInRight,
.fadeInUp,
.fadeInDown,
.fadeInLeft,
.fadeInRight,
.bounceInDown,
.zoomIn,
.zoomOutLeft,
.zoomOutRight,
.slideInLeft,
.wobble {
  visibility: visible;
}

/* ==========================================================================
Navigation
========================================================================== */
.header-nav.open {
  visibility: visible;
  opacity: 0.9;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.nav-toggle {
  position: absolute;
  top: 0;
  right: 15px;
  z-index: 999999;
  padding: 10px 35px 16px 0;
  cursor: pointer
}

.nav-toggle:focus {
  outline: none
}

.nav-toggle span,
.nav-toggle span:before,
.nav-toggle span:after {
  content: "";
  position: absolute;
  display: block;
  width: 35px;
  height: 3px;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
  border-radius: 1px;
  background: $white-color;
  cursor: pointer
}

.nav-toggle span:before {
  top: -10px
}

.nav-toggle span:after {
  bottom: -10px
}

.nav-toggle span,
.nav-toggle span:before,
.nav-toggle span:after {
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out
}

.nav-toggle.active span {
  background-color: transparent
}

.nav-toggle.active span:before,
.nav-toggle.active span:after {
  top: 0
}

.nav-toggle.active span:before {
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

.nav-toggle.active span:after {
  top: 10px;
  -moz-transform: translatey(-10px) rotate(-45deg);
  -ms-transform: translatey(-10px) rotate(-45deg);
  -o-transform: translatey(-10px) rotate(-45deg);
  -webkit-transform: translatey(-10px) rotate(-45deg);
  transform: translatey(-10px) rotate(-45deg)
}

.navicon {
  position: absolute;
  height: 26px;
  right: 10px;
  top: 48px;
  visibility: hidden;
  transition: all 300ms ease-in-out;
}

/* ==========================================================================
Hero
========================================================================== */
.hero {
  min-height: 100vh;
  background: $theme-dark-color-overlay url('../img/hero-min.jpg?as=webp') center center;
  background-blend-mode: darken;
  webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;

  h1 {
    color: $txt-light;
    margin-bottom: 40px;
  }

  .hero-content {
    min-height: 85vh;
  }

  .hero-content img {
    width: 56%;
    position: absolute;
    bottom: 2%;
    left: 22%;
  }
}

.navigation {
  transition: all 300ms ease-in-out;
  position: fixed;
  width: 100%;
  z-index: 999;

  &.dev {

    &:after {
      content: "DEVELOP";
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background-color: #f00;
      opacity: .7;
      color: #fff;
      text-align: center;
    }
  }
}

.fixed {
  background-color: $theme-dark-color-faded;
}

header {
  padding: 50px 0 10px 0;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  position: relative;
  width: 1170px;
  margin: 0 auto;
  transition: padding 300ms ease-in-out;

  a {
    color: $txt-light;
    text-transform: uppercase;
    font-size: $nav-font-size;

    &.login {
      margin-right: 20px;
    }
  }

  .logo {
    display: inline-block;

    img {
      height: 50px;
    }
  }

  ul.primary-nav {
    margin: 0 0 0 75px;
    padding: 0;

    li {
      display: inline;

      a {
        color: $txt-light;
        padding-right: 25px;

        &:hover {
          color: $accent-color;
        }
      }

      &:last-child a {
        padding-right: 0px;
      }
    }
  }

  ul.member-actions {
    li {
      display: inline;

      a {
        color: $txt-light;

        &:hover {
          color: $accent-color;
        }
      }
    }
  }

  .header-nav {
    display: inline-block;
  }

  .member-actions {
    position: absolute;
    right: 0;
    top: 55px;
    transition: all 300ms ease-in-out;
  }
}

.down-arrow {
  color: $white-color;
  font-size: 30px;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);

  a {
    color: $txt-light;
  }
}

/* ==========================================================================
Countdown ribbon
========================================================================== */
#countdown {
  overflow: visible;

  .ribbon {
    display: inline-block;
    width: 150px;
    height: 235px;
    background: $theme-light-color;
    color: $section-light;
    font-weight: $semibold;

    .ribbon-emojis {
      font-size: 3em;
    }

    .ribbon-rem-days {
      font-size: 5em;
    }

    .ribbon-first-line {
      font-size: 2em;
    }

    .ribbon-second-line {
      font-size: 1em;
    }

    &:after {
      content: "";
      display: block;
      position: relative;
      top: 14px;
      width: 0;
      height: 0;
      border-width: 10px 75px 25px 75px;
      border-style: solid;
      border-color: $theme-light-color $theme-light-color transparent $theme-light-color;
    }
  }
}

/* ==========================================================================
Intro
========================================================================== */
#intro {
  padding-top: 40px;

  .iandmlogo {
    width: 25%;
  }

  img {
    width: 100%;
    height: auto;
  }

  p {
    text-align: center;
  }

  .poem {
    font-size: $font-size-h3;
    color: $h3-color;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .poem-author {
    padding-left: 10em;
    font-size: small;
    font-weight: $bold;
    color: $h3-color;
  }
}

/* ==========================================================================
Photo Carousel
========================================================================== */
#photo-carousel {
  position: relative;
  width: 100%;
  height: 80vh;
  overflow: hidden;
}

#photo-deck,
#swipe-helper-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#photo-deck {
  &>div {
    position: absolute;
    width: 100vw;
    height: 100vh;
    will-change: transform;
    display: flex;
    align-items: center;
    justify-content: center;

    &>div {
      background-color: $white-color;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      box-sizing: content-box;
      will-change: transform;
      border: 1em solid $white-color;
      border-bottom: 4em solid $white-color;
      border-radius: 3px;
      box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
    }
  }
}

#swipe-helper-overlay {
  i {
    position: absolute;
    top: 35vh;
    font-size: 5em;
    color: $white-color;
    background-color: $theme-light-color-faded;
    border-radius: 50%;
    padding: 16px 20px;
  }
}

/* ==========================================================================
.events
========================================================================== */
.events {
  .leftcol {
    padding-right: 100px;
    border-right: solid 1px $keyline-color;
  }

  .rightcol {
    padding-left: 100px;
  }

  .families {
    border-top: solid 1px $keyline-color;
    margin-top: 40px;

    img {
      width: 100px;
      height: 100px;
      margin-bottom: 2em;
      border-radius: 50%;
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
    }

    p {
      padding-bottom: 0;
    }
  }

  .time {
    color: $txt-primary;
  }
}

/* ==========================================================================
Instagram
========================================================================== */
#instagram {
  overflow: hidden;

  img {
    width: 100%;
  }


  .instagram-gradient {
    background-image: linear-gradient(45deg, #fdf497 0%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

/* ==========================================================================
Embed video
========================================================================== */
#video-bg {
  height: 300px;
  overflow: hidden;
}

#video-content {
  width: 100%;
  height: 100%;
  padding-top: 110px;
  position: relative;
  background: rgba(17, 17, 17, 0.5);
  text-align: center;
}

#video-content h5 {
  color: $white-color;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 10px;
}

#video-content p {
  color: $white-color;
}

/* ==========================================================================
Google map
========================================================================== */
#map-canvas,
#map-content-wrapper {
  height: 500px;
}

#map-canvas {
  width: 100%;
}

#map-content-wrapper {
  position: relative;
  margin-top: -502px;
}

#map-content {
  margin-top: 40px;
  padding: 50px 60px;
  background: $white-color;
  -webkit-transition: opacity 0.5s, visibility 0.6s, margin-top 0.5s;
  transition: opacity 0.5s, visibility 0.6s, margin-top 0.5s;
}

#btn-show-content {
  padding: 20px;
  background: $white-color;
  cursor: pointer;
  display: inline-block;
  -webkit-transition: opacity 0.5s, visibility 0.6s, margin-top 0.5s;
  transition: opacity 0.5s, visibility 0.6s, margin-top 0.5s;
}

.toggle-map-content {
  opacity: 0;
  visibility: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

#btn-show-accomodation {
  margin: 4em;
}

#stay22-widget {
  width: 100%;
  height: 80vh;
}

/* ==========================================================================
RSVP form
========================================================================== */
.rsvp {
  padding-bottom: 140px;

  .rsvp-form {
    .rsvp-btn {
      margin-bottom: 15px;
      padding: 15px 0;
      border-radius: 3px;
      width: 100%;
      font-size: 13px;
    }

    .form-group {
      margin: 0 auto 10px;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      border-radius: 5px;
      border: solid 1px $keyline-color;
      padding: 1em;

      &>* {
        margin: 1em 0;
      }

      label {
        &:nth-child(1) {
          margin: 0;
        }

        &:last-child {
          margin-left: 1em;
        }
      }

      input[type="radio"],
      input[type="checkbox"] {
        position: relative;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .title {
        padding: 5px 5px 5px 25px;
        border-radius: .5em;
      }

      .checkmark {
        position: absolute;
        height: 20px;
        width: 20px;
        background-color: #eee;
      }

      .checkmark {
        border-radius: .5em;
      }

      input:checked {

        &~.checkmark,
        &~.title {
          background-color: $accent-color;
          color: $white-color;
        }

        &~.checkmark:after {
          display: block;
        }
      }

      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      .checkmark:after {
        left: 7px;
        top: 2px;
        width: 7px;
        height: 14px;
        border: solid $white-color;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    .form-input-group {
      width: 100%;
      height: 55px;
      margin: 0 auto 10px;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      border-radius: 5px;
      border: solid 1px $keyline-color;
      text-align: left;
      position: relative;

      i {
        color: $accent-color;
        font-size: 14px;
      }

      i:after {
        content: "";
        height: 30px;
        width: 1px;
        border-right: solid 1px $keyline-color;
        position: absolute;
        top: 50%;
        left: 30px;
        transform: translateY(-50%);
      }

      i.fa-user,
      i.fa-users,
      i.fa-phone,
      .fa-key {
        font-size: 14px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 20px;
      }

      input {
        padding-left: 68px;
        width: 100%;
        height: 100%;
        -moz-border-radius: 6px;
        -webkit-border-radius: 6px;
        border-radius: 6px;
        border: none;
      }

      input::-webkit-input-placeholder {
        color: $form-placeholder;
      }

      input:-moz-placeholder {
        color: $form-placeholder;
      }

      input::-moz-placeholder {
        color: $form-placeholder;
      }

      input:-ms-input-placeholder {
        color: $form-placeholder;
      }
    }
  }

  #rsvp-response {
    border: 1px solid $keyline-color;
    border-radius: 1em;
  }

  #add-to-cal {
    a {
      background-size: 15px 100%;
      background-position: 20%;
      background-repeat: no-repeat;
      margin: 30px 3px;
    }

    a.icon-google {
      background-image: url(../img/icons/icon-google.svg);
    }

    a.icon-ical {
      background-image: url(../img/icons/icon-apple.svg);
    }

    a.icon-outlook {
      background-image: url(../img/icons/icon-outlook.svg);
    }

    a.icon-yahoo {
      background-image: url(../img/icons/icon-yahoo.svg);
    }
  }
}

/* ==========================================================================
Footer
========================================================================== */
footer {
  .content {
    border-top: 1px solid $keyline-color;
    padding-bottom: 20px;
  }

  .to-top-wrapper {
    font-size: 30px;
    display: block;
    cursor: pointer;
    margin: -25px auto 50px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 1px solid $keyline-color;
    background: $white-color;
  }

  .to-top-wrapper .to-top {
    display: block;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }

  .to-top-wrapper .to-top:hover {
    margin-top: -5px;
  }

  span.fa-heart {
    color: #ff415c;
    font-size: 18px;
    margin: 0 2px;
  }

  .credits {
    a::after {
      content: '';
      margin: auto;
      display: block;
      width: 7em;
      height: 3em;
      background: url(/src/img/rect-transp-black.svg) center right;
      background-size: contain;
      background-repeat: no-repeat;
      filter: contrast(0.1);
      -webkit-transition: all 0.4s;
      -o-transition: all 0.4s;
      -moz-transition: all 0.4s;
      transition: all 0.4s;
    }

    a:hover:after {
      filter: contrast(1);
    }
  }
}

/* ==========================================================================
Bootstrap override
========================================================================== */
body.modal-open {
  padding-right: 0 !important;
}

.modal-body {
  padding: 25px;
}

/* ==========================================================================
Queries
========================================================================== */
@media screen and (max-width: 1200px) {
  header {
    width: 90%;
    margin: 0 auto;
  }

}

/* Responsive nav kicks in */
@media screen and (max-width: 991px) {
  header {
    width: 90%;
  }

  .header-nav {
    position: fixed;
    background-color: $theme-dark-color;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.5s, visibility 0s 0.5s;
    transition: opacity 0.5s, visibility 0s 0.5s;
  }

  ul.member-actions {
    position: static;
  }

  .navicon {
    visibility: visible;
  }

  header .nav-wrapper {
    display: absolute;
  }

  header nav {
    position: relative;
    top: 45%;
    -moz-transform: translatey(-45%);
    -ms-transform: translatey(-45%);
    -o-transform: translatey(-45%);
    -webkit-transform: translatey(-45%);
    transform: translatey(-45%);
  }

  nav ul li a {
    color: $white-color;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 600;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out
  }

  nav ul li {
    margin-bottom: 25px
  }

  nav ul.primary-nav li {
    display: block;
  }

  nav ul.member-actions li {
    display: block;
  }

  nav a.login {
    margin-right: 0px;
  }

  nav ul.primary-nav {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  nav ul.primary-nav li a {
    padding-right: 0;
  }

  nav .member-actions {
    position: static;
    padding: 0;
    text-align: center;
  }

  nav ul.primary-nav li,
  nav ul.member-actions li {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    -moz-transform: translate3d(0, -80px, 0);
    -ms-transform: translate3d(0, -80px, 0);
    -o-transform: translate3d(0, -80px, 0);
    -webkit-transform: translate3d(0, -80px, 0);
    transform: translate3d(0, -80px, 0);
    -moz-transition: -moz-transform 0.5s, opacity 0.5s;
    -o-transition: -o-transform 0.5s, opacity 0.5s;
    -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
    transition: transform 0.5s, opacity 0.5s;
    -moz-transition: -moz-transform 0.5s, opacity 0.5s;
    -o-transition: -o-transform 0.5s, opacity 0.5s;
    -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
    transition: transform 0.5s, opacity 0.5s
  }

  header .header-nav.open ul.primary-nav li,
  header .header-nav.open ul.member-actions li {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  nav ul.primary-nav li:first-child {
    -moz-transition-delay: 0.05s;
    -o-transition-delay: 0.05s;
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s
  }

  nav ul.primary-nav li:nth-child(2) {
    -moz-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s
  }

  nav ul.primary-nav li:nth-child(3) {
    -moz-transition-delay: 0.15s;
    -o-transition-delay: 0.15s;
    -webkit-transition-delay: 0.15s;
    transition-delay: 0.15s
  }

  nav ul.primary-nav li:nth-child(4) {
    -moz-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s
  }

  nav ul.member-actions li:first-child {
    -moz-transition-delay: 0.25s;
    -o-transition-delay: 0.25s;
    -webkit-transition-delay: 0.25s;
    transition-delay: 0.25s
  }

  nav ul.member-actions li:nth-child(2) {
    -moz-transition-delay: 0.30s;
    -o-transition-delay: 0.30s;
    -webkit-transition-delay: 0.30s;
    transition-delay: 0.30s
  }

  .last {
    margin: 0;
  }

  #intro {
    .poem {
      margin-top: 1em;
    }
  }

  .events {
    .leftcol {
      padding: 0 15px;
      margin-bottom: 15px;
      border-right: none;
    }

    .rightcol {
      padding: 0 20px;
      margin-top: 30px;
    }

    .families {
      border-top: none;
    }
  }

  #map-content {
    margin-top: 0;
    padding: 35px;
  }

  #video-bg {
    height: 190px;
  }

  #video-content {
    padding-top: 55px;
  }

  .rsvp {
    padding-top: 40px;
  }
}

@media screen and (max-width: 768px) {
  .hero .hero-content img {
    width: 76%;
    bottom: 0;
    left: 12%;
  }
}

@media screen and (max-width: 640px) {
  .hero .hero-content img {
    width: 86%;
    left: 7%;
  }

  #eng-pics .img-wrap i {
    font-size: 25px;
    position: absolute;
    width: 25px;
    height: 25px;
    top: 10px;
    right: 10px;
  }

}

@media screen and (max-width: 480px) {
  .hero .hero-content img {
    width: 100%;
    left: 0;
  }

  .hero .btn {
    display: block;
    width: 80%;
    margin: 0 auto;
  }

  .hero .btn:first-of-type {
    margin-bottom: 20px;
  }

  .hero h1 {
    font-size: 50px;
  }

  .btn-margin-right {
    margin-right: 0px;
  }

}

@media screen and (max-width: 320px) {
  .rsvp {
    padding-top: 20px;
  }
}