// Layout variables partial

$section-padding: 40px 0;
$section-margin: 40px 0;

section.intro, section.rsvp {
	background: $section-light;
}
section.events, section.map, #photo-carousel, #rsvp-response {
	background: $section-dark;
}