// Google Fonts
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,300,600,700,800);

// Custom Font
@font-face {
  font-family: 'Zephyrus';
  src: url('/src/fonts/zephyrus.eot');
  src: url('/src/fonts/zephyrus.eot?#iefix') format('embedded-opentype'),
    url('/src/fonts/zephyrus.woff2') format('woff2'),
    url('/src/fonts/zephyrus.woff') format('woff'),
    url('/src/fonts/zephyrus.ttf') format('truetype'),
    url('/src/fonts/zephyrus.svg#Zephyrus') format('svg');
  font-weight: normal;
  font-style: normal;
}

// Typography partial
@import "colors";

// Fonts

$sans-serif: 'Open Sans',
sans-serif;
$serif: Georgia,
'Times New Roman',
serif;
$calligraph: 'Zephyrus',
serif;

// Weights

$light: 300;
$normal: 400;
$semibold: 500;
$bold: 700;

// Sizes

$font-size-h1: 60px;
$font-size-h2: 40px;
$font-size-h3: 24px;
$font-size-h4: 18px;
$font-size-h5: 15px;
$font-size-p: 15px;
$nav-font-size: 13px;

// Responsive Sizes

$responsive-h1: 40px;

// Line height

$base-line-height: 29px;

// Styles

/* ==========================================================================
Typography
========================================================================== */
p {
  font-size: $font-size-p;
  line-height: $base-line-height;
  color: $txt-primary;
  padding-bottom: 20px;
}

h1 {
  font-size: $font-size-h1;
}

h2 {
  font-size: $font-size-h2;
  color: $h2-color;
}

h3 {
  color: $h3-color;
  font-size: $font-size-h3;
  font-weight: $bold;
  letter-spacing: 3px;
  line-height: $base-line-height;
}

h4 {
  font-size: $font-size-h4;
  color: $h4-color;
}

h5 {
  font-size: $font-size-h5;
  color: $h5-color;
  text-transform: uppercase;
  font-weight: $semibold;
}

.calligraph {
  font-family: $calligraph;
}

.bold {
  font-weight: $bold;
}

.light {
  font-weight: $light;
}